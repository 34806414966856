.body_container{
    display: flex;
    flex-direction: column;
}

.body_slides{
    height: 70vh;
    overflow: hidden;
}

.body_slides figure{
    position: relative;
    width: 500%;
    margin: 0;
    left: 0;
    animation: slider 20s infinite;
}

.body_slides img{
    width: 20%;
    float: left;
    box-shadow: inset 0 0 20px #000;
}

@keyframes slider{
    0% {
        left: 0;
    }
    20% {
        left: 0%;
    }
    25% {
        left: -100%;
    } 
    45% {
        left: -100%;
    } 
    50% {
        left: -200%;
    } 
    70% {
        left: -200%;
    }
    75% {
        left: -300%;
    } 
    95% {
        left: -300%;
    } 
    100% {
        left: -400%;
    } 
}

.body_sets{
    background-color: #f4f4f4;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 2.4rem;
    min-height: 50vh;
    flex-wrap: wrap;
}

.body_sets_heading_title{
    width: stretch;
    margin: 1rem;
    font-size: 2rem;
    font-weight: bold;
}

.body_sets_heading_details{
    padding: 0 1rem 2rem 1rem;
}

.body_sets a{
    text-decoration: none;
}

.set{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    width: 250px;
    margin: 3rem 1.2rem;
    box-shadow: 0 4px 6px 0 #eee, 0 4px 6px 0 #eee;
}

.set_profile_img{
    width: 250px;
    height: 140px;
    overflow: hidden;
}

.set_profile_img img{
    width: 250px;
}

.set_details{
    width: stretch;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem 0.8rem 1rem 0.8rem;
}

.set_title{
    color: #000;
    font-weight: 550;
    font-size: 1rem;
}

.set_description{
    color: #000;
    padding-top: 0.4rem;
    font-size: 0.8rem;
}

.set_price{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    font-weight: bold;
    font-size: 1.4rem;
    color: #000;
    padding: 10px 0 0 10px;
}

.body_description{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #125;
    color: #fff;
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 5rem;
    padding: 2rem 4rem;
    box-shadow: 0 1px 4px 0 #eee, 0 1px 4px 0 #eee;
}

.body_description_upcoming_courses{
    font-size: 2rem;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1.4rem;
}

.body_pending_set{
    width: 80%;
    background: #fff;
    padding: 0.4rem;
    margin: 1rem;
    bottom: 1rem;
    color: #125;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: left;
}

.body_pending_set_details{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.body_pending_set_title{
    flex: 30;
}

.body_pending_set_number{
    background: #125;
    flex: 1 0 auto;
    color: #fff;
    border-radius: 5%;
    text-align: center;
    margin: 1rem;
    padding: 15px;
}

@media (max-width: 480px){
    .body_slides{
        height: 40vh;
    }

    .body_slides img{
        height: stretch;
        box-shadow: inset 0 0 10px #000;
    }

    .body_description{
        font-size: 1.2rem;
        margin-bottom: 2rem;
        padding: 5rem 1rem;
    }

    .body_pending_set{
        width: 100%;
    }

    .body_pending_set_title{
        flex: 10;
    }

    .body_sets{
        justify-content: center;
        padding: 0.1rem;
    }

    .set{
        width: 84%;
        margin: 2rem 0.8rem;
    }
    
    .set_profile_img{
        width: stretch;
        height: 160px;
    }

    .set_profile_img img{
        width: 360px;
    }

    .set_price{
        margin: 0 0 5px 10px;
    }

}