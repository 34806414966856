.starting_container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.starting_image{
    background-color: #000;
    width: 100%;
    height: 60vh;
    overflow: hidden;
    text-align: center;
}

.starting_image img{
    height: stretch;
}

.starting_body{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 1rem;
}

.starting_title{
    font-weight: bold;
    font-size: 2rem;
}

.starting_description{
    font-size: 1rem;
    margin: 0.4rem 0 2rem 0;
}

.starting_actions{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 4rem;
}

.starting_preview{
    margin-bottom: 1rem;
}

.starting_preview a, .starting_preview input{
    background-color: #fff;
    border: 1px solid #125;
    border-radius: 0px;
    color: #125;
    text-decoration: none;
    padding: 0.3rem 0.8rem 0.4rem;
    margin: 0.4rem;
    cursor: pointer;
}

.starting_add_to_cart{
    background-color: #334477;
    border: none;
    border-radius: 0px;
    color: #fff;
    text-decoration: none;
    padding: 0.3rem 0.8rem 0.5rem;
    margin: 0.4rem 0.4rem 1.2rem 0.4rem;
    cursor: pointer;
}

.starting_buy_now{
    margin-bottom: 1rem;
}

.starting_buy_now a, .starting_buy_now input{
    background-color: #125;
    border: none;
    border-radius: 0px;
    color: #fff;
    font-weight: bold;
    text-decoration: none;
    padding: 0.3rem 0.8rem 0.5rem;
    margin: 0.4rem;
    cursor: pointer;
}

.body_flashcards{
    background-color: #f4f4f4;
    min-height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.body_flashcards_top{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.body_flashcards_top a{
    text-decoration: none;
}

.body_flashcards_back_to_starting{
    background-color: #fff;
    color: #444;
    width: 25px;
    height: 25px;
    opacity: 60%;
    padding: 0.8rem;
    margin: 0.8rem;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 1px 2px 0 #ccc, 0 1px 2px 0 #ccc;
}

.body_flashcards_counter{
    width: fit-content;
    height: fit-content;
    background-color: #fff;
    color: #555;
    opacity: 40%;
    font-size: 0.8rem;
    font-weight: bold;
    padding: 0.4rem 0.2rem;
    margin: 1rem;
    border-radius: 8%;
    box-shadow: 0 1px 2px 0 #ccc, 0 1px 2px 0 #ccc;
}

.body_flashcard{
    width: 400px;
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    text-align: center;
    position: relative;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 #ccc, 0 1px 2px 0 #ccc;
}

.card{
    width: 360px;
}

.body_flashcard_front, .body_flashcard_back{
    width: 280px;
    max-height: 240px;
    background-color: #fff;
    backface-visibility: hidden;
    opacity: 0.99;
    padding: 1rem;
    position: absolute;
    overflow: auto;
    vertical-align: middle;
    transition: transform 0.4s linear;
}

.body_flashcard_front{
    transform: rotateY(0deg);
}

.body_flashcard_back{
    transform: rotateY(180deg);
}

.body_flashcard_back_question{
    font-size: 1rem;
}

.flip{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
}

.flip_btn{
    background-color: #182;
    color: #fff;
    width: 25px;
    height: 25px;
    padding: 1rem;
    margin: 0.8rem;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 1px 2px 0 #ccc, 0 1px 2px 0 #ccc;
}

.navigation{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
}

.navigation_prev{
    background-color: #35f;
    color: #fff;
    width: 25px;
    height: 25px;
    padding: 0.8rem;
    margin: 0.8rem;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 1px 2px 0 #ccc, 0 1px 2px 0 #ccc;
}

.navigation_next{
    background-color: #35f;
    color: #fff;
    width: 25px;
    height: 25px;
    padding: 0.8rem;
    margin: 0.8rem;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 1px 2px 0 #ccc, 0 1px 2px 0 #ccc;
}

.navigation_right{
    width: 25px;
    height: 25px;
    padding: 0.8rem;
    margin: 0.8rem;
    border-radius: 50%;
}

.body_flashcard_quiz_container{
    width: 320px;
    height: 340px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.2rem;
    text-align: center;
}

.card_quiz{
    background-color: #fff;
    box-shadow: 0 1px 2px 0 #ccc, 0 1px 2px 0 #ccc;
}

.body_flashcard_quiz{
    max-height: 340px;
    overflow: auto;
    font-size: 1rem;
    padding: 1rem;
    text-align: left;
}

.body_flashcard_quiz_result{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    max-height: 340px;
    overflow: auto;
    font-size: 1rem;
    margin: auto 0;
}

.body_flashcard_quiz_result h3{
    font-weight: 500;
    font-size: 2rem;   
    margin-bottom: 0.4rem;
}

.body_flashcard_quiz_correct_result{
    font-weight: bolder;
    font-size: 5rem;
    color: #182;
}

.quiz_choice_result{
    padding: 0.4rem;
    color: #fff;
}

.quiz_actions{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


@media (max-width: 480px){  
    .starting_image{
        width: 100%;
        max-height: 31vh;
        padding-bottom: -1rem;
    }

    .starting_image img{
        width: stretch;
        height: auto;
        object-fit: cover;
    }

    .starting_title{
        font-weight: bold;
        font-size: 1.8rem;
    }

    .body_flashcard_front, .body_flashcard_back{
        width: 80%;
        max-height: 240px;
    }

    .body_flashcard{
        width: 90%;
        font-size: 1.2rem;
    }
    
    .flip_btn{
        font-size: 1.2rem;
        padding: 1.4rem;
        margin: 1rem;
    }

    .navigation_prev{
        padding: 1rem;
        margin: 1rem;
    }

    .navigation_next{
        padding: 1rem;
        margin: 1rem;
    }

    .navigation_right{
        padding: 1rem;
        margin: 1rem;
    }

    .body_flashcard_quiz{
        max-height: 320px;
    }
}