.body_checkout{
    height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem 12rem;
}

.body_checkout_heading{
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: 1rem;
}

.body_checkout_orders{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-shadow: 0 1px 2px 0 #eee, 0 1px 2px 0 #eee;
}

.body_checkout_order{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    box-shadow: 0 2px 4px 0 #eee, 0 2px 4px 0 #eee;
}

.body_checkout_order_img{
    height: 120px;
}

.body_checkout_order_img img{
    height: 100%;
}

.body_checkout_order_details{
    flex: 6;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 0.4rem;
}

.body_checkout_order_title{
    font-weight: 450;
    font-size: 1.2rem;
    padding: 0.8rem 1rem;
}

.body_checkout_order_tags{
    background-color: #49f;
    font-size: 0.6rem;
    padding: 0.2rem 0.8rem;
    margin-left: 1rem;
    border-radius: 10px;
    color: #fff;
}

.body_checkout_order_tag{
    background-color: #49f;
}

.body_checkout_order_amount{
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 1.6rem;
    padding: 0.6rem;
}

.body_checkout_confirm{
    padding: 1rem 0;
    text-align: center;
}

.body_checkout_confirm_btn{
    width: stretch;
    background-color: #152;
    padding: 0.4rem;
    color: #fff;
    font-weight: bold;
}

.body_checkout_confirm button{
    background-color: #152;
    width: stretch;
    font-size: 0.8rem;
}

.stripe_btn_div{
    height: 0px;
}

.body_confirmation{
    margin: 1rem 2.4rem 1rem 2.4rem;
    min-height: 96vh;
}

.body_confirmation a{
    text-decoration: none;
}

.body_confirmation_recommendation_sets{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

@media (max-width: 480px){
    .body_checkout{
        padding: 1rem 1rem;
    }
    .body_checkout_order_img{
        height: 80px;
        flex: 2;
    }

    .body_checkout_order{
        padding: 0;
    }

    .body_checkout_order_details{
        flex: 8;
        padding: 0.1rem;
    }

    .body_checkout_order_title{
        font-weight: 500;
        font-size: 0.9rem;
        padding: 0.4rem;
    }

    .body_checkout_order_tags{
        display: none;
    }

    .body_checkout_order_amount{
        font-size: 1rem;
        padding: 0.4rem;
    }

    .body_checkout_confirm button{
        font-size: 1rem;
    }
}