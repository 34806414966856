.header_container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.8rem 0.4rem;
    box-shadow: 0 1px 4px 0 #eee, 0 1px 4px 0 #eee;
}

.header_left{
    flex: 2;
}

.header_left a{
    text-decoration: none;
    color: #000;
}

.header_left img{
    height: 1rem;
    padding: 0.4rem;
    margin-left: 0.2rem;
}

.header_middle{
    flex: 5;
}

.header_right{
    flex: 2;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 1rem;
}

.header_right a{
    text-decoration: none;
    color: #000;
    margin-left: 2rem;
}


.header_right img{
    width: 24px;
    height: 24px;
    margin-top: -0.4rem;
}

.header_right_cart{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 1.6rem;
    margin-top: -0.4rem;
}

.header_signout{
    margin-left: 1rem;
}

@media (max-width: 480px){
    .header_left{
        flex: 4;
    }

    .header_middle{
        flex: 2;
    }
    
    .header_right{
        flex: 7;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-items: center;
        font-size: 0.8rem;
        padding-right: 0.2rem;
    }
    
    .header_right a{
        margin-left: 0.4rem;
        margin-right: 0.4rem;
    }
    
    .header_myaccount{
        margin-right: 0.2rem;
    }

    .header_signout{
        margin-left: 0.4rem;
    }

    .header_right img{
        width: 24px;
        height: 16px;
        margin-top: -0.2rem;
    }
        
    .header_right_cart{
        margin-left: 0.6rem;
    }
}